import React from "react";

import { navigate } from "gatsby";

import { PageProps } from "gatsby";

import { Layout, Container } from "components/layout";
import { ViewHeader } from "components/view/ViewHeader";
import {
  Form,
  FormCheckboxInput,
  FormColumn,
  FormContent,
  FormFooter,
  FormRow,
  FormTextInput,
  FormTitle,
  FormButton,
  FormButtonLink,
} from "components/common/Form";
import { useUser } from "hooks/api";
import { AdminSidebar } from "components/view/AdminSidebar";

interface AdminUserPageProps extends PageProps {
  id: string;
}

export default function AdminUserPage(props: AdminUserPageProps) {
  const { id } = props;

  const user = useUser(id);

  React.useEffect(() => {
    if (!user.isLoading && !user.data) navigate("/404");
  }, [user]);

  const handleSubmit = React.useCallback(
    (data: FormData) => {
      user.updateUser(data);
      navigate("/administration/users");
    },
    [id]
  );

  return (
    <Layout>
      <ViewHeader />
      <Container hasSidebar>
        <AdminSidebar {...props} />
        <Container noPadding>
          <Form onSubmit={handleSubmit}>
            <FormTitle>User Detail</FormTitle>
            <FormContent>
              <FormRow>
                <FormColumn>
                  <FormTextInput
                    field="username"
                    label="Username"
                    disabled
                    defaultValue={user.data?.username}
                  />
                </FormColumn>
                <FormColumn>
                  <FormTextInput
                    field="email"
                    label="Email"
                    disabled
                    defaultValue={user.data?.email}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FormTextInput
                    field="first_name"
                    label="First Name"
                    defaultValue={user.data?.first_name}
                  />
                </FormColumn>
                <FormColumn>
                  <FormTextInput
                    field="last_name"
                    label="Last Name"
                    defaultValue={user.data?.last_name}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FormCheckboxInput
                    field="is_staff"
                    label="Administrator"
                    defaultChecked={user.data?.is_staff}
                  />
                </FormColumn>
                <FormColumn>
                  <FormCheckboxInput
                    field="is_active"
                    label="Active"
                    defaultChecked={user.data?.is_active}
                  />
                </FormColumn>
              </FormRow>
            </FormContent>
            <FormFooter>
              <FormButton transparent>Save User</FormButton>
              <FormButtonLink to="/administration/users" label="Cancel" />
            </FormFooter>
          </Form>
        </Container>
      </Container>
    </Layout>
  );
}
